<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>Grup</label>
                <v-select
                  label="name"
                  taggable
                  :options="groups"
                  placeholder="Grup seçebilirsiniz"
                  v-model="selectedGroup"
                  class="w-100"
                >
                  <template #selected-option="{ name }">
                    <strong style="font-size: 10px; line-height: 22px">{{
                      name
                    }}</strong>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>Marka</label>
                <v-select
                  label="name"
                  placeholder="Marka Seçebilirsiniz"
                  :options="brands"
                  v-model="selectedBrand"
                  class="w-100"
                >
                  <template #selected-option="{ name }">
                    <strong style="font-size: 10px; line-height: 22px">{{
                      name
                    }}</strong>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>Model</label>
                <v-select
                  label="name"
                  placeholder="Model Seçebilirsiniz"
                  :options="models"
                  v-model="selectedModel"
                  class="w-100"
                >
                  <template #selected-option="{ name }">
                    <strong style="font-size: 10px; line-height: 22px">{{
                      name
                    }}</strong>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>OE No</label>
                <b-form-input
                  v-model="oeNo"
                  class="d-inline-block mr-1"
                  placeholder="0000.."
                />
              </b-col>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>Parça No</label>
                <b-form-input
                  v-model="partNo"
                  class="d-inline-block mr-1"
                  placeholder="0000.."
                />
              </b-col>
              <b-col cols="12" md="1" class="mb-md-0 mb-2">
                <div class="d-flex mt-2">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="searchClick(1)"
                  >
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Ara</span>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12" md="1" class="mb-md-0 mb-2">
                <div class="d-flex mt-2">
                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                    @click="clearClick"
                  >
                    <span class="align-middle">Temizle</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <div class="d-flex mt-2">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="showUploadPanel = true"
                  >
                    <feather-icon icon="UploadIcon" class="mr-50" />
                    <span class="align-middle">Excelden Sipariş Aktar</span>
                  </b-button>
                </div>
              </b-col>

              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <div class="d-flex mt-2">
                  <b-button
                    style="margin-top: 0x"
                    type="button"
                    variant="primary"
                    @click="downloadSample()"
                  >
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    <span class="align-middle">Örnek Excel İndir</span>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="mb-md-0 mb-2"> </b-col>
              <b-col cols="12" md="1" class="mb-md-0 mb-2"> </b-col>
              <b-col cols="12" md="3" class="mb-md-0 mb-2">
                <div class="d-flex mt-1">
                  <b-form-radio-group
                    @change="changeLevel()"
                    v-model="selectedLevel"
                    :options="levels"
                    class="demo-inline-spacing"
                    name="radio-inline"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            :busy="show"
            :small="true"
            ref="refUserListTable"
            class="position-relative"
            :items="productsData.items"
            responsive
            :fields="tableColumns"
            primary-key="id"
            sort-by.sync="id"
            show-empty
            empty-text="Eşleşen ürün bulunamadı."
            head-variant="dark"
          >
            <template #cell(priceType)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.priceType }}
              </div>
            </template>
            <template #cell(packageUnit)="data">
              <div class="item-quantity d-flex justify-content-end">
                <strong>{{ data.item.packageUnit }}</strong>
              </div>
            </template>
            <template #cell(priceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                <strong>{{ data.item.priceFormatted }}</strong>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="item-quantity d-flex justify-content-center">
                <b-form-spinbutton
                  v-model="data.item.cartUnit"
                  size="sm"
                  min="0"
                  max="10000000"
                  :step="data.item.packageUnit"
                  class="ml-75"
                  inline
                />
              </div>
            </template>
            <template #cell(imgViewer)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  v-b-modal.modal-center
                  @click="showImageClick(data)"
                >
                  <feather-icon icon="ImageIcon" />
                </b-button>
              </div>
            </template>
            <template #cell(cart)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="addCartClick(data)"
                >
                  <feather-icon icon="ShoppingCartIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Toplam ürün sayısı:<strong>
                    {{ productsData.totalRecords }}</strong
                  >
                </span>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="productsData.pageNumber"
                  :total-rows="productsData.totalRecords"
                  :per-page="productsData.pageSize"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="changePage(productsData.pageNumber)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <b-modal
        id="modal-center"
        centered
        title="Ürün Görseli"
        :hide-footer="true"
      >
        <b-img :src="selectedImgUrl" fluid alt="Fluid image')" />
      </b-modal>
      <b-modal
        id="modal-cartImport"
        v-model="showUploadPanel"
        centered
        title="Excelden Sipariş Aktarma"
        :hide-footer="true"
      >
        <b-form-file
          v-model="file"
          placeholder="Bir dosya seçin veya buraya bırakın"
          drop-placeholder="Dosyayı buraya bırakın.."
          accept=".xlsx, .xls"
        />

        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="cartUpload"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="align-middle">Yükle</span>
            </b-button>
          </div>
        </div>
      </b-modal>
      
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    ToastificationContent,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BFormRadioGroup,
    BFormGroup,
    BAvatar,
    BFormFile,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      productsData: [],
      cleanProdcuts: [],
      groups: [],
      selectedGroup: null,
      brands: [],
      selectedBrand: null,
      models: [],
      selectedModel: null,
      partNo: "",
      oeNo: "",
      tableColumns: [
        { key: "groups", label: "Ürün Grubu", class: "productLongButton" },
        { key: "partNo", label: "Parça No", class: "productYearButton" },
        {
          key: "caption",
          label: "Parça Tanımı",
          class: "productLongButton",
        },
        { key: "brands", label: "Araç Marka", class: "productLongThButton" },
        { key: "models", label: "Araç Model", class: "productLongTwoButton" },
        { key: "years", label: "Yıl", class: "productYearButton" },
        { key: "oeNo", label: "OE Numarası", class: "bTableThStyle" },
        { key: "packageUnit", label: "Paket Ad.", class: "productButton" },
        {
          key: "priceFormatted",
          label: "Liste Fiyatı",
          class: "productButton",
        },
        { key: "priceType", label: "Para Birimi", class: "productButton" },
        { key: "imgViewer", label: "Detay", class: "productButton" },
        { key: "actions", label: "Miktar", class: "productUnitButton" },
        { key: "cart", label: "İşlem", class: "productButton" },
      ],
      pageLoad: false,
      show: false,
      selectedImgUrl: "",
      levels: [
        { text: "TÜMÜ", value: "ALL" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
        { text: "D", value: "D" },
      ],
      selectedLevel: "ALL",
      file: null,
      showUploadPanel: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.show = true;
      this.selectedGroup = null;
      this.selectedBrand = null;
      this.selectedModel = null;
      this.oeNo = "";
      this.partNo = "";

      this.productsData.pageNo = 1;
      this.productsData.pageSize = 10;
      this.searchClick(1);

      await this.$http.get("/Product/GetProductGroups").then((response) => {
        this.groups = response.data.result;
      });

      await this.$http.get("/Product/GetProductBrands").then((response) => {
        this.brands = response.data.result;
      });

      await this.$http.get("/Product/GetProductModels").then((response) => {
        this.models = response.data.result;
      });
      this.show = false;
    },
    changePage(pageNo) {
      //Burası biraz karışık ama tek fonksyon ile 3 iş yapınca sonuç böyle oluyor...
      if (pageNo != null && pageNo > 1) {
        this.searchClick(pageNo);
        this.pageLoad = true;
      }

      if (pageNo == 1 && this.pageLoad) {
        this.searchClick(pageNo);
        this.pageLoad = false;
      }
    },
    changeLevel() {
      this.searchClick(1);
    },
    searchClick(pageNo) {
      this.show = true;
      let bid = 0;
      let mid = 0;
      let gid = 0;

      if (this.selectedBrand != null) {
        bid = this.selectedBrand.id;
      }

      if (this.selectedModel != null) {
        mid = this.selectedModel.id;
      }

      if (this.selectedGroup != null) {
        gid = this.selectedGroup.id;
      }

      this.$http
        .get(
          `Product/GetNewProducts?BrandId=${bid}&ModelId=${mid}&GroupId=${gid}&OeNo=${this.oeNo}&PartNo=${this.partNo}&Level=${this.selectedLevel}&PageSize=${this.productsData.pageSize}&PageIndex=${pageNo}`
        )
        .then((response) => {
          this.productsData = response.data.result;
          this.cleanProdcuts = this.productsData.items;
          if (this.selectedLevel != "ALL") {
            this.productsData = this.productsData.filter(
              (s) => s.level == this.selectedLevel
            );
          }
          this.show = false;
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    clearClick() {
      this.init();
    },
    showImageClick(data) {
      this.selectedImgUrl = data.item.coverImgUrl;
    },
    addCartClick(data) {  
      if (data.item.cartUnit === 0) {
        this.$swal({
              title: "Uyarı",
              text: "Lütfen öncelikle miktar belirtiniz.",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              confirmButtonText: "Kapat",
              buttonsStyling: false,
            });
      } else {
        this.show = true;
        this.$http
          .get(
            `Cart/AddCartProduct?productId=${data.item.id}&productUnit=${data.item.cartUnit}`
          )
          .then((response) => {
            data.item.cartUnit = 0;
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "CartIcon",
                text: response.data.message,
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.show = false;

            this.$swal({
              title: "Hata!",
              text: error.response.data.responseException.exceptionMessage,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              confirmButtonText: "Kapat",
              buttonsStyling: false,
            });
          });
      }
    },
    cartUpload() {
      if (this.file === null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce dosya seçiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false,
        });
        return;
      }

      this.show = true;
      this.showUploadPanel = false;
      let formData = new FormData();
      formData.append("file", this.file);
      this.$http
        .post(`Cart/ImportCartWithExcel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.show = false;

          if (response.data.message.includes("bulunamadı")) {
            let notFoundList = response.data.result;
            let message = response.data.message;
            var i;
            for (i = 0; i < notFoundList.length; i++) {
              message += "<br/> Ürün Kodu: " + notFoundList[i];
            }
            message +=
              "<br/> <strong>Lütfen ürün kodlarını kontrol ettikten sonra dosyayı tekrar yükleyiniz.</strong>";
            this.$swal({
              title: "Uyarı!",
              html: message,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              confirmButtonText: "Kapat",
              buttonsStyling: false,
            });
            return;
          }

          this.$swal({
            title: "İşlem başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Sepete Git",
            buttonsStyling: false,
            preConfirm() {
              router.push({ name: "mycarts-list" });
            },
          });
          this.file = null;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.file = null;
        });
    },
    downloadSample() {
      window.open("https://document.d7ksuper.com/Dokuman/b2b/OrnekSiparis.xlsx", "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";

.table .bTableThStyle {
  max-width: 8rem !important;
  text-overflow: ellipsis !important;
}
.table .productButton {
  width: 4.5rem !important;
  text-overflow: ellipsis !important;
}
.table .productUnitButton {
  width: 8rem !important;
  text-overflow: ellipsis !important;
}
.table .productYearButton {
  width: 5rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongButton {
  max-width: 12rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongTwoButton {
  max-width: 15rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongThButton {
  max-width: 17rem !important;
  text-overflow: ellipsis !important;
}
</style>